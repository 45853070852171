<template>
  <b-card :title="$t('settings')">
    <!--<b-row>
      <b-col sm="12"  class="text-right">
        <b-button size="sm" variant="success" @click="openModal"><fa icon="plus" /></b-button>
      </b-col>
    </b-row>-->
    <table class="table">
      <thead>
      <th>Розділ</th>
      <th class="text-center">Дія</th>
      </thead>
      <tbody>
      <tr v-for="(item, index) in operations" :key="index">
        <td>{{ item.name }}</td>
        <td class="text-center">
          <router-link :to="{ name: 'operation.section', params: { slug: item.slug } }"><fa icon="cogs" /></router-link>
        </td>
      </tr>
      </tbody>
    </table>
    <b-modal id="section-modal" size="sm" hide-footer>
      <div class="form-group">
        <label for="name">{{ $t('name') }}</label>
        <input type="text" v-model="form.name" required class="form-control form-control-sm">
      </div>
      <div class="form-group">
        <label for="status">{{ $t('status') }}</label>
        <select name="status" class="form-control form-control-sm">
          <option value="true">{{ $t('enabled') }}</option>
          <option value="false">{{ $t('disabled') }}</option>
        </select>
      </div>
      <b-button size="sm" variant="primary" @click="save">{{ $t('save') }}</b-button>
    </b-modal>
  </b-card>
</template>
<script>
import axios from 'axios'
import Form from 'vform'
export default {
  data: () => ({
    form: new Form({
      name: '',
      status: true
    }),
    operations: null
  }),
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      this.$root.$refs.loading.start()
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.get(apiUrl + 'operations/sections').then(resp => {
        if (resp.data) {
          this.operations = resp.data
          this.$root.$refs.loading.finish()
        }
      })
    },
    async addSection () {
      this.$root.$refs.loading.start()
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await this.form.post(apiUrl + 'operations/sections', this.form).then(resp => {
        if (resp.data && resp.data.status) {
          this.getData()
          this.form.reset()
          this.$bvModal.hide('section-modal')
          this.$root.$refs.loading.finish()
        }
      })
    },
    async removeItem (id) {
      this.$root.$refs.loading.start()
      const result = confirm(this.$t('want_to_delete'))
      if (result) {
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.delete(apiUrl + 'operations/sections/' + id).then(resp => {
          if (resp.data.status) {
            this.getData()
            this.$root.$refs.loading.finish()
          }
        })
      }
    },
    save () {
      if (this.form.id) {
        // update
      } else {
        this.addSection()
      }
    },
    openModal () {
      this.$bvModal.show('section-modal')
    }
  }
}
</script>
